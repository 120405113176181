<template>
  <div style="min-height: 100vh">
    <ProductHeader />
    <v-container grid-list-xs xs="12" lg="6" sm="5" md="4">
      <component :is="recent.component" />
      <div v-for="(value, key) in Object.keys(prod_for())" :key="key">
        <h3 class="nunito mt-5 mb-3 font-weight-bold leaf--text red--text">
          {{ value }}
        </h3>

        <div class="products_list__main__grid__container">
          <div class="products_list__grid__container">
            <div
              class="products_list__grid_content"
              v-for="(v, i) in prod_for()[value]"
              :key="i"
              @click="!isOutOfStock(v) && selectProduct(v)"
              :style="{ cursor: isOutOfStock(v) ? 'not-allowed' : 'pointer' }"
            >
              <div
                class="products_list__grid_content_selected_cart"
                v-if="renderQuantityFromCart(v) > 0"
              >
                <div class="products_list__grid_content_selected_cart_text">
                  {{ renderQuantityFromCart(v) }}
                </div>
              </div>
              <img
                v-if="v.media_urls.length > 0"
                :src="image(v.media_urls[0])"
              />
              <img v-else :src="emptyImage()" />
              <div class="products_list__grid_content_bottom">
                <div class="products_list__grid_content_bottom__name">
                  {{ v.name }}
                </div>
                <div>
                  <div
                    class="lato font-weight-bold font-italic red--text"
                    v-if="isOutOfStock(v)"
                  >
                    Sold Out
                  </div>
                  <div v-else class="flex">
                    <div class="" v-if="v.price_type == 0">
                      <div
                        v-if="v.variants.length > 1"
                        class="products_list__grid_content_bottom__price"
                      >
                        RM {{ currency(getMinVariantPrice(v.variants)) }} - RM
                        {{ currency(getMaxVariantPrice(v.variants)) }}
                      </div>
                      <div
                        v-else
                        class="products_list__grid_content_bottom__price"
                      >
                        RM {{ currency(v.unit_price) }}
                      </div>
                    </div>
                    <div
                      class="products_list__grid_content_bottom__price"
                      v-else-if="v.price_type == 1"
                    >
                      RM {{ currency(v.unit_price) }}
                    </div>
                    <div
                      class="products_list__grid_content_bottom__price"
                      v-else
                    >
                      RM {{ currency(v.unit_price) }}
                    </div>
                    <div
                      class="products_list__grid_content_bottom__price ml-2 grey--text line-through"
                      v-if="v.before_discount"
                    >
                      RM {{ currency(v.before_discount) }}
                    </div>
                  </div>
                </div>
                <div class="products_list__grid_content__add_cart_container">
                  <v-btn
                    class="products_list__grid_content__add_cart"
                    v-if="!isOutOfStock(v)"
                    >Add</v-btn
                  >
                  <div
                    v-else
                    class="products_list__grid_content__add_cart_placeholder"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-row class="mt-10">
          <!-- <v-col v-for="(v, i) in prod_for()[value]" :key="i"> -->
          <!-- <v-card class="rounded-lg" max-width="230" @click="selectProduct(v)"
              :disabled="v.total_left == 0 || v.out_of_stock ? true : false" outlined width='154'
              style="box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;">
              <v-img height="81" width='154' v-if="v.media_urls.length > 0" :src="image(v.media_urls[0])"></v-img>
              <v-img v-else height="81" width='154' :src="emptyImage()"></v-img>

              <v-card-text class="">
                <div class="">
                </div>
                <span class="d-flex flex-row"
                  style="-webkit-box-orient: vertical; text-overflow: ellipsis; max-width: 100px;">
                  <h5 class="flex-grow black--text" style="margin-top: -10px; margin-left: -6px;">{{ v.name }}</h5>
                  <v-spacer />
                
                </span>
                <div class="flex justify-center">
                  <h5 class="lato font-weight-bold font-italic red--text" v-if="v.total_left == 0 || v.out_of_stock">
                    Sold Out
                  </h5>
                  <div v-else class="flex">
                    <h5 class="lato font-weight-bold" v-if="v.price_type == 0"
                      style="margin-top: -5px; margin-left: -6px;">
                      <div v-if="v.variants.length > 1">
                        RM {{ currency(getMinVariantPrice(v.variants)) }} - RM {{ currency(getMaxVariantPrice(v.variants))
                        }}
                      </div>
                      <div v-else>RM {{ currency(v.unit_price) }}</div>
                    </h5>
                    <h5 class="lato font-weight-bold" style="margin-top: -5px; margin-left: -6px;"
                      v-else-if="v.price_type == 1">
                      RM {{ currency(v.unit_price) }}
                    </h5>
                    <h5 class="lato font-weight-bold" style="margin-top: -5px; margin-left: -6px;" v-else>
                      RM {{ currency(v.unit_price) }}
                    </h5>
                    <h5 class="lato font-weight-bold ml-2 grey--text line-through"
                      style="margin-top: -5px; margin-left: -6px;" v-if="v.before_discount">
                      RM {{ currency(v.before_discount) }}
                    </h5>
                  </div>
                </div>
                <v-spacer></v-spacer>

              </v-card-text>
              <div style="width: 152px; padding-left: 8px; padding-right: 8px;">
                <v-btn style="text-transform: none; background-color: #F85757; color: white;"
                  class="w-full mb-2">Add</v-btn>
              </div>

            </v-card> -->

          <!-- <v-card class="mb-3" @click="selectProduct(v)"
              :disabled="v.total_left == 0 || v.out_of_stock ? true : false">
              <v-img height="81px" max-width="1840px" class="rounded-lg line-bg" v-if="v.media_urls.length > 0"
                :src="image(v.media_urls[0])"></v-img>
              <v-img v-else height="81px" max-width="184px" class="rounded-lg" :src="emptyImage()"></v-img>
              <div class=" nunito py-3">
                <h5>
                  <b>{{ v.name }}</b>
                </h5>
                <div class="flex justify-center">
                  <h5 class="lato font-weight-bold font-italic red--text" v-if="v.total_left == 0 || v.out_of_stock">
                    Sold Out
                  </h5>
                  <div v-else class="flex">
                    <h5 class="lato font-weight-bold ml-1" v-if="v.price_type == 0">
                      <div v-if="v.variants.length > 1">
                        RM {{ currency(getMinVariantPrice(v.variants)) }} - RM {{ currency(getMaxVariantPrice(v.variants))
                        }}
                      </div>
                      <div v-else>RM {{ currency(v.unit_price) }}</div>
                    </h5>
                    <h5 class="lato font-weight-bold ml-1" v-else-if="v.price_type == 1">
                      RM {{ currency(v.unit_price) }}
                    </h5>
                    <h5 class="lato font-weight-bold ml-1" v-else>
                      RM {{ currency(v.unit_price) }}
                    </h5>
                    <h5 class="lato font-weight-bold ml-2 grey--text line-through" v-if="v.before_discount">
                      RM {{ currency(v.before_discount) }}
                    </h5>
                  </div>
                </div>
              
              </div>
            </v-card> -->
          <!-- </v-col> -->
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductHeader from "../../../common/ProductHeader.vue";
export default {
  name: "grid",
  components: {
    ProductHeader,
  },
  data() {
    return {
      first: null,
      recent: { component: () => import("../Recent") },
    };
  },

  computed: {
    ...mapGetters({
      product: "getFilteredProducts",
      cart: "getCart",
      franchise: "getFranchise",
      selectedTag: "getSelectedTags",
    }),

    currency() {
      return require("currency.js");
    },
    cekKarakter(val) {
      let text = val;
      let result = text.substring(0, 10);
      let hasil = result.length;

      return hasil;
    },

    tag_list() {
      var tags = {};
      this.product.forEach((prod) => {
        prod.tags.forEach(function (tag) {
          if (!tag.hidden_tag) {
            return (tags[tag.id] = tag);
          }
        });
      });
      return Object.values(tags).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },

  methods: {
    isOutOfStock(product) {
      return product.total_left == 0 || product.out_of_stock;
    },
    renderQuantityFromCart(product) {
      const quantity = this.cart
        .filter((item) => item.id === product.id)
        .map((d) => d.quantity)
        .reduce((a, b) => Number(a) + Number(b), 0);
      return quantity;
    },
    getMinVariantPrice(variants) {
      return Math.min(...variants.map((variant) => variant.price));
    },
    getMaxVariantPrice(variants) {
      return Math.max(...variants.map((variant) => variant.price));
    },
    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },

    selectProduct(i) {
      this.$store.commit("setScrollPosition", window.pageYOffset);
      this.$store.dispatch("updateSP", i);
      this.track(i.name);
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if (table_id && outlet_id) {
        // static link
        this.$router.push({
          name: "ProductsDetail",
          params: {
            child: this.$route.params.child,
            outlet_id,
            table_id,
            page: "product",
          },
        });
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionProductsDetail",
          params: { session },
        });
      } else {
        // shop link
        this.$router.push({
          name: "ShoplinkProductsDetail",
          params: { child: this.$route.params.child, page: "product" },
        });
      }
    },

    track(val) {
      this.$gtag.event("view_product", {
        event_category: "view_product",
        event_label: "view_product",
        value: val,
      });
    },

    prod_for() {
      var list = this.product;

      // Prioritaskan produk berdasarkan media
      var priority_list = [
        ...list.filter((p) => p.media_urls.length > 0),
        ...list.filter((p) => p.media_urls.length == 0),
      ];

      // Urutkan produk berdasarkan atribut numerik jika ada
      priority_list = priority_list.sort(function (a, b) {
        return (a.priority || 0) - (b.priority || 0);
      });

      // Kelompokkan produk berdasarkan tag pertama mereka
      const groupedByTag = {};

      for (const obj of priority_list) {
        if (obj.tags.length > 0) {
          const tagName = obj.tags[0].name;

          const isNumberTag = !isNaN(tagName);
          const tagPriority = isNumberTag ? parseFloat(tagName) : tagName;

          if (!groupedByTag[tagPriority]) {
            groupedByTag[tagPriority] = [];
          }
          groupedByTag[tagPriority].push(obj);
        }
      }

      // Urutkan kelompok tag berdasarkan jenisnya (angka atau nama)
      const sortedGroupedByTag = Object.keys(groupedByTag)
        .sort((a, b) => {
          const aIsNumber = !isNaN(a);
          const bIsNumber = !isNaN(b);

          if (aIsNumber && bIsNumber) {
            return parseFloat(a) - parseFloat(b); // Urutkan angka
          } else if (aIsNumber) {
            return -1; // Angka sebelum nama
          } else if (bIsNumber) {
            return 1; // Nama setelah angka
          } else {
            return a.localeCompare(b);
          }
        })
        .reduce((acc, key) => {
          acc[key] = groupedByTag[key];
          return acc;
        }, {});

      return sortedGroupedByTag;
    },
  },

  mounted() {
    // console.log(this.product)
    //  console.log("grid");
    // this.scroll(this.tag_list[0].name);
    if (this.tag_list.length > 0) {
      this.first = this.tag_list[0].name;
    }
  },
};
</script>
<style lang="scss">
.products_list__main__grid__container {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;

  .products_list__grid__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media (min-width: 350px) and (max-width: 600px) {
      justify-content: space-between;
    }

    .products_list__grid_content {
      @media (min-width: 350px) and (max-width: 600px) {
        margin-top: 40px;
      }

      position: relative;

      .products_list__grid_content_selected_cart {
        position: absolute;
        right: 5px;
        top: 5px;

        .products_list__grid_content_selected_cart_text {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: white;
          color: #f85757;
          justify-content: center;
          font-weight: bolder;
          align-items: center;
          display: flex;
        }
      }

      width: 30%;
      border-radius: 15px;
      margin: 10px;
      // box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media (min-width: 350px) and (max-width: 600px) {
        width: 45%;
        margin: 5px;
      }

      @media (min-width: 200px) and (max-width: 300px) {
        width: 100%;
        margin: 5px;
      }

      img {
        width: 100%;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        height: 170px;
        object-fit: cover;

        @media (min-width: 350px) and (max-width: 600px) {
          height: 110px;
        }
      }

      .products_list__grid_content_bottom {
        width: 100%;
        padding-left: 10px;
        padding-right: 8px;
        padding-bottom: 8px;

        .products_list__grid_content_bottom__name {
          font-size: 12px;
          font-weight: 400;
          margin-top: 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 3px;
        }

        .products_list__grid_content_bottom__price {
          font-size: 12px;
          font-weight: 400;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 10px;
        }

        .products_list__grid_content__add_cart_container {
          width: 100%;

          .products_list__grid_content__add_cart {
            color: white;
            background-color: #f85757;
            width: 100%;
            border-radius: 10px;
            text-transform: none;
          }

          .products_list__grid_content__add_cart_placeholder {
            visibility: hidden;
            height: 36px; /* height of v-btn */
          }
        }
      }
    }
  }
}

.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flexcard {
  display: flex;
  flex-direction: column;
}

.v-tabsactive {
  background-color: #ffff !important;
}

.height {
  height: 100%;
}

.red_tab_text {
  color: #f85757 !important;
}

.greener {
  background-color: #8e8e8e !important;
}

.line-through {
  text-decoration: line-through red;
}

.v-slide-group__prev {
  display: none !important;
  visibility: hidden;
}
</style>
